import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" alt="An image of the breadcrumb component" src="https://github.com/primer/design/assets/2313998/712e582d-6b51-4b03-a141-747fd31a30e9" />
    <h2>{`Usage`}</h2>
    <p>{`Breadcrumbs are used to show taxonomical context on pages that are many levels deep in a site’s hierarchy. Breadcrumbs show and link to pages higher up in the ancestry.`}</p>
    <p>{`Breadcrumbs are most appropriate on pages that:`}</p>
    <ul>
      <li parentName="ul">{`Are many levels deep on a site`}</li>
      <li parentName="ul">{`Do not have a section-level navigation`}</li>
      <li parentName="ul">{`May need the ability to quickly go back to the previous (parent) page`}</li>
      <li parentName="ul">{`All items must contain links, with the last item as the user's current context`}</li>
    </ul>
    <h2>{`Anatomy`}</h2>
    <p>{`Breadcrumbs are made of links (indicating parents), dividers, and the current page. The default breadcrumbs component can be modified to change the number of items within the chain.`}</p>
    <img width="960" alt="An image showing the anatomy of the breacrumb component" src="https://github.com/primer/design/assets/2313998/068d9757-0a9c-46cd-a3b8-2720447d9fad" />
    <Caption mdxType="Caption">Note: The space between items is set to 0px as the divider has padding built into it.</Caption>
    <h2>{`Options`}</h2>
    <h3>{`Breadcrumb length`}</h3>
    <p>{`By default the breadcrumbs component can show up to 10 items within the chain.`}</p>
    <img width="960" alt="An image showing various breadcrumbs with different numbers of items in the breadcrumb chain" src="https://github.com/primer/design/assets/2313998/0007110c-0e54-4775-aeac-d8de1dfc916a" />
    <h2>{`Accessibility and usability expectations`}</h2>
    <p>{`Breadcrumbs provide users with a structured set of links to both help them understand where they are within the context of a site, and to allow them to more easily jump directly to previous sections within the site's structure.`}</p>
    <p>{`The last item in the breadcrumb trail refers to the current page. To convey this visually, the link is styled differently from the preceding links. For screen reader users, this information is conveyed programmatically through the use of the `}<inlineCode parentName="p">{`aria-current`}</inlineCode>{` attribute in the rendered output.`}</p>
    <p>{`The text of each breadcrumb link should match the heading element (`}<inlineCode parentName="p">{`h1`}</inlineCode>{`–`}<inlineCode parentName="p">{`h6`}</inlineCode>{`) or the unique part of the `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` element that it is linked to. If the breadcrumb link text is not unique, it should be made unique by adding additional context. While this approach should be used in the majority of cases, if the breadcrumb link text is overly verbose, it can be made more concise, as long as it still provides sufficient information to the user. For example, the last item in a breadcrumb trail for a blog post could be the full title of the blog post itself, or (as it is likely long, and arguably redundant) just be generically labelled as "Blog post".`}</p>
    <h3>{`Built-in accessibility features`}</h3>
    <p>{`This component is rendered as a `}<inlineCode parentName="p">{`<nav>`}</inlineCode>{` navigation landmark region, with an accessible name of "Breadcrumbs".`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<nav … aria-label="Breadcrumbs"> … </nav>
`}</code></pre>
    <p>{`This makes the breadcrumb navigation easily discoverable for screen reader users who navigate via page landmarks.`}</p>
    <p>{`The individual breadcrumb items are rendered as an `}<inlineCode parentName="p">{`<ol>`}</inlineCode>{` ordered list, with each item marked up as a link in a separate `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` list item.`}</p>
    <h3>{`Implementation requirements`}</h3>
    <p>{`In the Rails implementation, the link in the last item is automatically given an `}<inlineCode parentName="p">{`aria-current="page"`}</inlineCode>{`.`}</p>
    <p>{`In the current React implementation, you need to explicitly mark the last item as `}<inlineCode parentName="p">{`selected`}</inlineCode>{`, which results in `}<inlineCode parentName="p">{`aria-current="page"`}</inlineCode>{` being added to the link.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<Breadcrumbs>
  <Breadcrumbs.Item href="…"> … </Breadcrumbs.Item>
  <Breadcrumbs.Item href="…"> … </Breadcrumbs.Item>
  …
  <Breadcrumbs.Item href="…" selected> … </Breadcrumbs.Item>
</Breadcrumbs>
`}</code></pre>
    <h3>{`How to test the component`}</h3>
    <h4>{`Integration tests`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Each breadcrumb link has appropriate and descriptive link text`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For the React implementation, the last breadcrumb item has been explicitly marked as `}<inlineCode parentName="p">{`selected`}</inlineCode>{`, to visually and programmatically identify it as a link referring to the current page`}</p>
      </li>
    </ul>
    <h4>{`Component tests`}</h4>
    <ul>
      <li parentName="ul">{`The breadcrumb component is exposed as a navigation landmark with an appropriate name/label`}</li>
      <li parentName="ul">{`The breadcrumb items have an appropriate structure, such as an `}<inlineCode parentName="li">{`<ol>`}</inlineCode>{` ordered list`}</li>
      <li parentName="ul">{`For the Rails implementation, the last item link is programmatically identified as a link to the current page using `}<inlineCode parentName="li">{`aria-current="page"`}</inlineCode></li>
      <li parentName="ul">{`For the React implementation, if a breadcrumb item has been marked as `}<inlineCode parentName="li">{`selected`}</inlineCode>{`, the last item link in the rendered output is programmatically identified as a link to the current page using `}<inlineCode parentName="li">{`aria-current="page"`}</inlineCode></li>
    </ul>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Breadcrumbs" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      